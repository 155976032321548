var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "basic-card-block margin-top-0" }, [
    _vm._m(0),
    _c("h1", [_vm._v("Summary Form ????")]),
    _c("div", [
      _c(
        "div",
        { staticClass: "div-frac-13-avg" },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "Company Name", rules: _vm.$props.rules.reqRule },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    "value-key": "id",
                    disabled:
                      _vm.$attrs.propsView === _vm.ORDER_VIEW.VIEW_ORDER ||
                      _vm.$attrs.propsView === _vm.ORDER_VIEW.EDIT_ORDER ||
                      _vm.$props.linkedSalesTermContract !== null,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeCompanyName($event)
                    },
                  },
                  model: {
                    value: _vm.orderForm.buyerSysOrganizationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "buyerSysOrganizationId", $$v)
                    },
                    expression: "orderForm.buyerSysOrganizationId",
                  },
                },
                _vm._l(_vm.creditAssociationList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.counterpartyCompanyName,
                      value: item.counterpartySysOrganizationId,
                      disabled: item.status !== "ACTIVE",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Territory", rules: _vm.$props.rules.reqRule } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    "value-key": "id",
                    disabled:
                      _vm.$attrs.propsView === _vm.ORDER_VIEW.VIEW_ORDER ||
                      _vm.$attrs.propsView === _vm.ORDER_VIEW.EDIT_ORDER ||
                      !!_vm.$props.linkedSalesTermContract,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeCompanyName($event)
                    },
                  },
                  model: {
                    value: _vm.orderForm.buyerSysOrganizationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderForm, "buyerSysOrganizationId", $$v)
                    },
                    expression: "orderForm.buyerSysOrganizationId",
                  },
                },
                _vm._l(_vm.creditAssociationList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.counterpartyCompanyName,
                      value: item.counterpartySysOrganizationId,
                      disabled: item.status !== "ACTIVE",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Order Date", rules: _vm.rules.reqRule } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "dd-MM-yyyy",
                  disabled: _vm.$attrs.propsView === _vm.ORDER_VIEW.VIEW_ORDER,
                },
                on: {
                  change: function ($event) {
                    _vm.orderForm.nominationDate = null
                  },
                },
                model: {
                  value: _vm.orderForm.orderDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderForm, "orderDate", $$v)
                  },
                  expression: "orderForm.orderDate",
                },
              }),
            ],
            1
          ),
          !_vm.isVesselTBC
            ? _c(
                "el-form-item",
                { attrs: { label: "Search Vessel" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "filter-method": (val) =>
                          _vm.$emit("handleSearchMpaReceivingVessels", val),
                        filterable: "",
                        "popper-class": "custom-ship-selector",
                        disabled:
                          _vm.$attrs.propsView === _vm.ORDER_VIEW.VIEW_ORDER,
                        placeholder: "Select",
                      },
                      on: {
                        "visible-change": function ($event) {
                          return _vm.$emit("searchVesselvisibleChange", $event)
                        },
                        change: function ($event) {
                          return _vm.$emit("handleSelectVessel", $event)
                        },
                      },
                      model: {
                        value: _vm.vesselSearchKey,
                        callback: function ($$v) {
                          _vm.vesselSearchKey = $$v
                        },
                        expression: "vesselSearchKey",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "200px",
                            "font-size": "14px",
                            "font-weight": "500",
                            "margin-left": "20px",
                            "margin-bottom": "10px",
                          },
                        },
                        [_vm._v("Name")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "100px",
                            "font-size": "14px",
                            "font-weight": "500",
                          },
                        },
                        [_vm._v("IMO")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "14px",
                            "font-weight": "500",
                          },
                        },
                        [_vm._v("Licence")]
                      ),
                      _vm._l(_vm.searchedVesselList || [], function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            attrs: { label: item.shipName, value: item.id },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "200px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(_vm._s(item.shipName) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "100px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(_vm._s(item.imo))]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(_vm._s(item.licenceNo))]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex-between-row",
        staticStyle: {
          display: "flex",
          "flex-direction": "row",
          width: "400px",
        },
      },
      [
        _c("div", { staticClass: "flex-between-column" }, [
          _c(
            "div",
            {
              staticClass: "sub-title-text",
              staticStyle: { "text-align": "center", "font-size": "16px" },
            },
            [_vm._v(" XXXXXX1 Top")]
          ),
          _c(
            "div",
            {
              staticClass: "sub-title-text",
              staticStyle: { "text-align": "center", "font-weight": "400" },
            },
            [_vm._v("XXXXXX1 bottom ")]
          ),
        ]),
        _c("div", { staticClass: "flex-between-column" }, [
          _c(
            "div",
            {
              staticClass: "sub-title-text",
              staticStyle: { "text-align": "center", "font-size": "16px" },
            },
            [_vm._v(" XXXXXX2 Top")]
          ),
          _c(
            "div",
            {
              staticClass: "sub-title-text",
              staticStyle: { "text-align": "center", "font-weight": "400" },
            },
            [_vm._v("XXXXXX2 bottom ")]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }